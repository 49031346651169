import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function PrivacyPage() {
  return (
    <Layout>
      <SEO title="Privacy" />
      <h2>Privacy Policy</h2>
      <p>
        This website (learning objective.net) collects anonymous data related to
        requests from users. This information includes, but is not limited to,
        ip address, date and time, referring website, etc. None of this data is
        personally identifying. Aggregate data may be shared, for example, total
        number of unique visitors, or page views.
      </p>

      <p>
        Squarespace and Google Analytics collect information related to page
        views, number of pages per visit, and length of time on a certain page.
        Again, none of this data is personally identifying, and is only used to
        improve the website.
      </p>

      <p>
        If you opt-in to the email newsletter, I will occasionally send email
        related to the products I offer. Each email will contain unsubscribe
        details. I will not share your email address with anyone.
      </p>

      <p>
        Support requests by email will not be shared outside the company, but a
        support representative may contact you for further information, or
        follow up to see if an issue has been resolved. Again, I will not share
        your email address with anyone else.
      </p>

      <p>
        Products that connect to an outside service fall under the privacy
        policy for this service. There are no products that work in this way at
        this time.
      </p>
    </Layout>
  );
}
